import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Footer from "../../components/Footer/footer"
import Header from "../../components/Header/header"
import * as styles from "../../styles/products.module.css"

const Products = () => {
    return (
        <React.Fragment>
            <Helmet title="Silver Oaks - Products" />

            <Header invert={true} />

            <div className={styles.pageTitle}>
                <h1>Timber Applications</h1>
            </div>

            <div className={styles.categoriesContainer}>
                <div className={styles.categories}>
                    <div className={styles.category}>
                        <div className={styles.image}>
                            <StaticImage
                                src="../../images/structure.jpg"
                                alt="Timber structure"
                                objectFit="cover"
                                style={{height: "100%", width: "100%"}}
                            />
                        </div>
                        <h1>Construction</h1>
                        <Link to="/products/construction">Learn More</Link>
                    </div>
                    <div className={styles.category}>
                        <div className={styles.image}>
                            <StaticImage
                                src="../../images/kitchen.jpg"
                                alt="Kitchen"
                                objectFit="cover"
                                style={{height: "100%", width: "100%"}}
                            />
                        </div>
                        <h1>Furniture</h1>
                        <Link to="/products/furniture">Learn More</Link>
                    </div>
                    <div className={styles.category}>
                        <div className={styles.image}>
                            <StaticImage
                                src="../../images/crates.jpg"
                                alt="Timber crates"
                                objectFit="cover"
                                style={{height: "100%", width: "100%"}}
                            />
                        </div>
                        <h1>Pallets and Crates</h1>
                        <Link to="/products/pallets-crates">Learn More</Link>
                    </div>
                    <div className={styles.category}>
                        <div className={styles.image}>
                            <StaticImage
                                src="../../images/drum.jpg"
                                alt="Cable drum"
                                objectFit="cover"
                                style={{height: "100%", width: "100%"}}
                            />
                        </div>
                        <h1>Cable Drums</h1>
                        <Link to="/products/cable-drums">Learn More</Link>
                    </div>
                </div>
            </div>

            <Footer />
        </React.Fragment>
    )
}

export default Products
